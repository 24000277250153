import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import "../App.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Role() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [roleValue, setRoleValue] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const columns = [
    {
      field: "roleName",
      headerName: "Role Name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => params.row.createdAt.substring(0, 10),
    },
  ];

  const handleRoleValue = (event) => {
    const valueRole = event.target.value;
    setRoleValue(valueRole);
  };

  const submitResultRole = async (event) => {
    event.preventDefault();
    setRoleValue("");

    try {
      setIsBtnLoading(true);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V1}createRole`,
        {
          roleName: roleValue,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setIsBtnLoading(false);
        setData((prevData) => [
          ...prevData,
          {
            _id: response.data.data._id,
            roleName: response.data.data.roleName,
            createdAt: response.data.data.createdAt,
          },
        ]);

        return showSuccessModal(response.data.message);
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setError(message);
      return simulateError(message);
    }
  };

  const showSuccessModal = (successMessage) => {
    setSuccess(successMessage);
    setTimeout(() => {
      setSuccess(null);
      setOpen(false);
    }, 3000);
  };

  const simulateError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
      setOpen(false);
    }, 3000);
  };

  const SkeletonTable = () => (
    <div style={{ height: 450, width: "100%" }}>
      {[...Array(10)].map((_, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <Skeleton variant="rectangular" height={30} animation="wave" />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}fetchAllRoles`,
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (isLoading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Navbar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <SkeletonTable />
          </Box>
        </Box>
      </>
    );
  }

  const rowsWithIds = data.map(({ _id, roleName, createdAt }) => ({
    id: _id,
    roleName,
    createdAt,
  }));

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="filled" severity="success">
              {success}
            </Alert>
          )}
          <h3>All Roles</h3>
          {/* <Button
            variant="contained"
            sx={{ marginBottom: "10px" }}
            className="submitBtn"
            onClick={handleOpen}
          >
            Create New Role
          </Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Role
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Role Name"
                    variant="outlined"
                    value={roleValue}
                    onChange={handleRoleValue}
                  />
                </div>
                <Button
                  variant="contained"
                  sx={{ marginBottom: "10px", marginTop: "10px" }}
                  className="submitBtn"
                  onClick={submitResultRole}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Typography>
            </Box>
          </Modal>

          <div style={{ height: 450, width: "100%" }}>
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[2, 5, 10]}
              checkboxSelection
            />
          </div>
        </Box>
      </Box>
    </>
  );
}
