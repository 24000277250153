import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../App.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Language() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [languageValue, setLanguageValue] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDetail, setOpenDetail] = React.useState(false);
  const handleDetailClose = () => setOpenDetail(false);
  const [phraseCatDetail, setPhraseCatDetail] = useState([]);
  const [searchLanguageValue, setSearchLanguageValue] = useState("");
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteClose = () => setDeleteOpen(false);

  const columns = [
    {
      field: "languageName",
      headerAlign: "center",
      headerName: "Language Name",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      width: 250,
      renderCell: (params) => params.row.createdAt.substring(0, 10),
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   headerAlign: "center",
    //   width: 200,
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <DeleteIcon
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           handleModalDelete(params.row.id);
    //         }}
    //         className="deletedBtn"
    //         variant="contained"
    //       />
    //     </Box>
    //   ),
    // },
    {
      field: "view",
      headerName: "View",
      headerAlign: "center",
      width: 220,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <VisibilityIcon
            onClick={(e) => {
              e.stopPropagation();
              handleDetailOpen(params.row.id);
            }}
            className="viewBtn"
            variant="contained"
          />
        </Box>
      ),
    },
  ];

  const handleModalDelete = (dataId) => {
    setDeleteId(dataId);
    setDeleteOpen(true);
  };

  const handleSearchLanguageValue = async (event) => {
    setSearchLanguageValue(event.target.value);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_V1}searchLanguage`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            languageName: event.target.value.toLowerCase(),
          },
        }
      );
      if (response.data.data) {
        console.log(response.data.data);
        setData(response.data.data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleDetailOpen = async (languageDetailId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      navigate("/phraseCategoryDetail", {
        state: { data: languageDetailId },
      });
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_V1}fetchAllPhraseCategory`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: { languageId: languageDetailId },
        }
      );
      if (response.data) {
        setPhraseCatDetail(response.data.data);
        console.log(response.data);
        console.log(phraseCatDetail);
        setOpenDetail(true);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const handleDeleteClick = async () => {
    try {
      setIsBtnLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL_V1}deleteLanguage`,
        {
          headers: { authorization: `${token}` },
          params: {
            languageId: deleteId,
          },
        }
      );

      if (response.data) {
        setData((prevData) =>
          prevData.filter((language) => language._id !== deleteId)
        );
        setIsBtnLoading(false);
        showSuccessModal(response.data.message);
      }
    } catch (error) {
      console.error("Delete Language error:", error);
      setIsBtnLoading(false);
      simulateError(error);
    }
  };

  const handleLanguageValue = (event) => {
    const valueLanguage = event.target.value;
    setLanguageValue(valueLanguage);
  };

  const submitResultLanguage = async (event) => {
    event.preventDefault();
    setLanguageValue("");
    if (!languageValue || languageValue == "") {
      setError("Please fill language name");
    }
    try {
      setIsBtnLoading(true);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V1}createLanguage`,
        {
          languageName: languageValue,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setIsBtnLoading(false);
        setData((prevData) => [
          ...prevData,
          {
            _id: response.data.data._id,
            languageName: response.data.data.languageName,
            createdAt: response.data.data.createdAt,
          },
        ]);

        return showSuccessModal(response.data.message);
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setError(message);
      return simulateError(message);
    }
  };

  const showSuccessModal = (successMessage) => {
    setSuccess(successMessage);
    if (successMessage == "This Language has been Deleted") {
      setTimeout(() => {
        setSuccess(null);
        setDeleteOpen(false);
      }, 3000);
    } else {
      setOpen(false);
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    }
  };

  const simulateError = (errorMessage) => {
    setError(errorMessage);
    setOpen(false);
    setTimeout(() => {
      setError(null);

      setDeleteOpen(false);
    }, 3000);
  };

  const SkeletonTable = () => (
    <div style={{ height: 450, width: "100%" }}>
      {[...Array(10)].map((_, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <Skeleton variant="rectangular" height={30} animation="wave" />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}getAllLanguage`,
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Navbar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <SkeletonTable />
          </Box>
        </Box>
      </>
    );
  }

  const rowsWithIds = data.map(({ _id, languageName, createdAt }) => ({
    id: _id,
    languageName,
    createdAt,
  }));

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="filled" severity="success">
              {success}
            </Alert>
          )}
          <h3>All Languages</h3>
          {/* <Button
            variant="contained"
            sx={{ marginBottom: "10px" }}
            className="submitBtn"
            onClick={handleOpen}
          >
            Create New Language
          </Button> */}
          <div>
            <TextField
              id="outlined-basic"
              label="Language Name"
              variant="outlined"
              value={searchLanguageValue}
              onChange={handleSearchLanguageValue}
              sx={{ width: 250, marginBottom: "10px" }}
            />
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Language
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Language Name"
                    variant="outlined"
                    value={languageValue}
                    onChange={handleLanguageValue}
                  />
                </div>
                <Button
                  variant="contained"
                  sx={{ marginBottom: "10px", marginTop: "10px" }}
                  className="submitBtn"
                  onClick={submitResultLanguage}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Typography>
            </Box>
          </Modal>

          <Modal
            open={openDetail}
            onClose={handleDetailClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modalStyle"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                All Phrase Category
                {phraseCatDetail.length === 0 ? (
                  <div
                    style={{
                      height: "170px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>No data found</div>
                  </div>
                ) : (
                  phraseCatDetail.map((data) => (
                    <Card sx={{ maxWidth: 345, mb: 2 }}>
                      <CardMedia
                        sx={{ height: 100 }}
                        image={`${process.env.REACT_APP_BASE_URL}/${data.icon}`}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.phraseName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {data.createdAt}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))
                )}
              </Typography>
            </Box>
          </Modal>

          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Are you sure to delete this language
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                  className="deleteModalBtn"
                  onClick={handleDeleteClick}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Yes"
                  )}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  className="noBtn"
                  onClick={handleDeleteClose}
                >
                  No
                </Button>
              </Typography>
            </Box>
          </Modal>

          <div style={{ height: 450, width: "100%" }}>
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    display: "none",
                  },
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                ".MuiDataGrid-selectedRowCount": {
                  display: "none",
                },
              }}
              pageSizeOptions={[2, 5, 10]}
              checkboxSelection={false}
            />
          </div>
        </Box>
      </Box>
    </>
  );
}
