import Home from "./components/Home";
import Login from "./components/Login";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import User from "./components/User";
import Language from "./components/Language";
import PhraseCategory from "./components/PhraseCategory";
import Phrase from "./components/Phrase";
import Role from "./components/Role";
import Policy from "./components/Policy";
import TermsAndCondition from "./components/TermsAndCondition";
import PhraseCategoryDetail from "./components/PhraseCategoryDetail";
import PhraseDetail from "./components/PhraseDetail";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" exact element={<Login />}></Route>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/users" exact element={<User />}></Route>
          <Route path="/language" exact element={<Language />}></Route>
          <Route
            path="/phraseCategory"
            exact
            element={<PhraseCategory />}
          ></Route>
          <Route path="/phrase" exact element={<Phrase />}></Route>
          <Route path="/role" exact element={<Role />}></Route>
          <Route path="/policy" exact element={<Policy />}></Route>
          <Route
            path="/termsAndCondition"
            exact
            element={<TermsAndCondition />}
          ></Route>
          <Route
            path="/phraseCategoryDetail"
            exact
            element={<PhraseCategoryDetail />}
          ></Route>
          <Route path="/phraseDetail" exact element={<PhraseDetail />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
