import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "../App.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function User() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [selectedTypeOption, setSelectedTypeOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValueStatus, setSearchValueStatus] = useState("disabled");
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [titleValue, setTitleValue] = useState(null);
  const [bodyValue, setBodyValue] = useState(null);

  const handleDeleteClose = () => setDeleteOpen(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const columns = [
    {
      field: "profileImage",
      headerName: "Profile Image",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Avatar
          src={`${process.env.REACT_APP_BASE_URL}/${params.row.profileImage}`}
        />
      ),
    },
    {
      field: "fullName",
      headerAlign: "center",
      headerName: "Full Name",
      width: 150,
    },
    {
      field: "phoneNumber",
      headerAlign: "center",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "email",
      headerAlign: "center",
      headerName: "Email Address",
      width: 180,
    },
    {
      field: "country",
      headerAlign: "center",
      headerName: "Country",
      width: 150,
    },
    { field: "state", headerAlign: "center", headerName: "State", width: 150 },
    { field: "city", headerAlign: "center", headerName: "City", width: 150 },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();
            handleModalDelete(params.row.id);
          }}
          className="deletedBtn"
          variant="contained"
        />
      ),
    },
  ];

  const handleModalDelete = (dataId) => {
    setDeleteId(dataId);
    setDeleteOpen(true);
  };

  const handleTitle = (event) => {
    setTitleValue(event.target.value);
  };

  const handleBodyValue = (event) => {
    setBodyValue(event.target.value);
  };

  const handleTypeSelect = (event) => {
    setSearchValueStatus(null);
    const value = event.target.value;
    setSelectedTypeOption(value);
  };

  const handleSearchValue = async (event) => {
    setSearchValue(event.target.value);
    if (selectedTypeOption !== "" && selectedTypeOption === "fullName") {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}filterUser`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              fullName: event.target.value.toLowerCase(),
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data.data);
        }
      } catch (error) {
        setError(error);
      }
    } else if (selectedTypeOption !== "" && selectedTypeOption === "email") {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}filterUser`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              email: event.target.value.toLowerCase(),
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data.data);
        }
      } catch (error) {
        setError(error);
      }
    } else if (selectedTypeOption === "") {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}filterUser`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              email: "",
              fullName: "",
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data.data);
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  const SkeletonTable = () => (
    <div style={{ height: 450, width: "100%" }}>
      {[...Array(10)].map((_, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <Skeleton variant="rectangular" height={30} animation="wave" />
        </div>
      ))}
    </div>
  );

  const handleDeleteClick = async () => {
    try {
      setIsBtnLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL_V1}deleteUser`,
        { userId: deleteId },
        { headers: { authorization: `${token}` } }
      );

      if (response.data) {
        setData((prevData) => prevData.filter((user) => user._id !== deleteId));
        setIsBtnLoading(false);
        showSuccessModal(response.data.message);
      }
    } catch (error) {
      console.error("Delete user error:", error);
      setIsBtnLoading(false);
      simulateError(error);
    }
  };

  const showSuccessModal = (successMessage) => {
    setSuccess(successMessage);
    setDeleteOpen(false);
    setOpen(false);
    setTimeout(() => {
      setSuccess(null);
    }, 2000);
  };

  const simulateError = (errorMessage) => {
    setError(errorMessage);

    setTimeout(() => {
      setError(null);
      setDeleteOpen(false);
    }, 3000);
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedIds(selectionModel);
  };

  const submitNotification = async (event) => {
    event.preventDefault();
    setTitleValue("");
    setBodyValue("");
    setSelectedIds([]);

    try {
      setIsBtnLoading(true);
      if (!titleValue || titleValue == "") {
        setIsBtnLoading(false);
        setError("Please fill title");
        return simulateError("Please fill title");
      } else if (!bodyValue || bodyValue == "") {
        setIsBtnLoading(false);
        setError("Please fill body");
        return simulateError("Please fill body");
      }

      console.log({
        userArr: selectedIds,
        notificationTitle: titleValue,
        notificationBody: bodyValue,
      });

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V1}sendNotification`,
        {
          userArr: selectedIds,
          notificationTitle: titleValue,
          notificationBody: bodyValue,
        },

        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.status === 200) {
        setIsBtnLoading(false);

        return showSuccessModal(response.data.message);
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setError(message);
      return simulateError(message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}getAllUser`,
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Navbar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <SkeletonTable />
          </Box>
        </Box>
      </>
    );
  }

  const rowsWithIds = data.map(
    ({
      _id,
      profileImage,
      fullName,
      phoneNumber,
      email,
      country,
      state,
      city,
    }) => ({
      id: _id,
      profileImage,
      fullName,
      phoneNumber,
      email,
      country,
      state,
      city,
    })
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                position: "absolute",
                zIndex: 1500,
                width: "50%",
                margin: "auto",
              }}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert variant="filled" severity="success">
              {success}
            </Alert>
          )}
          <h3>All Users</h3>
          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Are you sure to delete this user
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                  className="deleteModalBtn"
                  onClick={handleDeleteClick}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Yes"
                  )}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  className="noBtn"
                  onClick={handleDeleteClose}
                >
                  No
                </Button>
              </Typography>
            </Box>
          </Modal>
          <Button
            variant="contained"
            sx={{ marginBottom: "10px" }}
            className="submitBtn"
            onClick={() => setOpen(true)}
          >
            Send Notification
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Notification
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    value={titleValue}
                    onChange={handleTitle}
                    sx={{ width: 250, marginBottom: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Body"
                    variant="outlined"
                    value={bodyValue}
                    onChange={handleBodyValue}
                    sx={{ width: 250 }}
                  />
                </div>
                <Button
                  variant="contained"
                  sx={{ marginBottom: "10px", marginTop: "10px" }}
                  className="submitBtn"
                  onClick={submitNotification}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Typography>
            </Box>
          </Modal>
          <div>
            <FormControl>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTypeOption}
                style={{
                  minWidth: "200px",
                  marginBottom: "20px",
                  marginRight: "10px",
                }}
                onChange={handleTypeSelect}
              >
                <MenuItem value="">
                  <em>Select Type</em>
                </MenuItem>
                <MenuItem value="email">Email Address</MenuItem>
                <MenuItem value="fullName">Full Name</MenuItem>
              </Select>
            </FormControl>
            <TextField
              disabled={searchValueStatus === "disabled"}
              id="outlined-basic"
              value={searchValue}
              onChange={handleSearchValue}
              label="Search"
              variant="outlined"
            />
          </div>

          <div style={{ height: 450, width: "100%" }}>
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[2, 5, 10]}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionModelChange}
            />
          </div>
        </Box>
      </Box>
    </>
  );
}
