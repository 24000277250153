import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router-dom";
import "../App.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PhraseCategoryDetail() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [categoryValue, setCategoryValue] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [languageId, setLanguageId] = React.useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [searchPhraseCategoryValue, setSearchPhraseCategoryValue] =
    useState("");
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteClose = () => setDeleteOpen(false);

  const location = useLocation();
  const receivedData = location.state?.data || "No data received";

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log("Selected File:", file);
  };

  const handleSearchPhraseCategoryValue = async (event) => {
    setSearchPhraseCategoryValue(event.target.value);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_V1}searchPhraseCategory`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            phraseCategoryName: event.target.value.toLowerCase(),
            languageId: receivedData,
          },
        }
      );
      if (response.data.data) {
        console.log(response.data.data);
        setData(response.data.data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleOpen = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      setOpen(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_V1}fetchSingleLanguage`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: { languageId: receivedData },
        }
      );
      if (response.data) {
        setLanguages(response.data.data);
        console.log(response.data);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const columns = [
    {
      field: "icon",
      headerName: "Icon",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => (
        <Avatar src={`${process.env.REACT_APP_BASE_URL}/${params.row.icon}`} />
      ),
    },
    {
      field: "languageId",
      headerName: "Language Name",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => params.row.languageId.languageName,
    },
    {
      field: "phraseName",
      headerName: "Phrase Name",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => params.row.createdAt.substring(0, 10),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => (
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();
            handleModalDelete(params.row.id);
          }}
          className="deletedBtn"
          variant="contained"
        />
      ),
    },
    {
      field: "view",
      headerName: "View",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <VisibilityIcon
            onClick={(e) => {
              e.stopPropagation();
              handleDetailOpen(params.row.id);
            }}
            className="viewBtn"
            variant="contained"
          />
        </Box>
      ),
    },
  ];

  const handleModalDelete = (dataId) => {
    setDeleteId(dataId);
    setDeleteOpen(true);
  };

  const handleDetailOpen = async (phraseCatDetailId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
      navigate("/phraseDetail", {
        state: { data: phraseCatDetailId, languageData: receivedData },
      });
    } catch (error) {
      setError(error);
      setIsLoading(false);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const handleDeleteClick = async () => {
    try {
      setIsBtnLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL_V1}deletePhraseCategory`,
        {
          headers: { authorization: `${token}` },
          params: {
            phraseCategoryId: deleteId,
          },
        }
      );

      if (response.data) {
        setData((prevData) =>
          prevData.filter((phraseCategory) => phraseCategory._id !== deleteId)
        );
        setIsBtnLoading(false);
        showSuccessModal(response.data.message);
      }
    } catch (error) {
      setIsBtnLoading(false);
      console.error("Delete Phrase Category error:", error);
      simulateError(error);
    }
  };

  const handleCategoryValue = (event) => {
    const valueCategory = event.target.value;
    setCategoryValue(valueCategory);
  };

  const submitResultCategory = async (event) => {
    event.preventDefault();
    setCategoryValue("");

    try {
      setIsBtnLoading(true);
      if (!languageId || languageId === "") {
        setIsBtnLoading(false);
        setError("Please select language name");
        return simulateError("Please select language name");
      } else if (!selectedFile || selectedFile === "") {
        setIsBtnLoading(false);
        setError("Please select an image");
        return simulateError("Please select an image");
      } else if (!categoryValue || categoryValue === "") {
        setIsBtnLoading(false);
        setError("Please fill category name");
        return simulateError("Please fill category name");
      }
      const formData = new FormData();
      formData.append("phraseName", categoryValue);
      formData.append("icon", selectedFile);
      formData.append("languageId", languageId);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V1}createPhraseCategory`,
        formData,

        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        setIsBtnLoading(false);
        setData((prevData) => [
          ...prevData,
          {
            _id: response.data.data._id,
            languageId: response.data.data.languageId.languageName,
            phraseName: response.data.data.phraseName,
            icon: response.data.data.icon,
            createdAt: response.data.data.createdAt,
          },
        ]);

        return showSuccessModal(response.data.message);
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setError(message);
      return simulateError(message);
    }
  };

  const showSuccessModal = (successMessage) => {
    setSuccess(successMessage);
    if (successMessage === "This Phrase Category has been Deleted") {
      setDeleteOpen(false);
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    } else {
      setOpen(false);
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    }
  };

  const handleLanguageValue = (event) => {
    const value = event.target.value;
    setLanguageId(value);
  };

  const simulateError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setDeleteOpen(false);
    }, 2000);
  };

  const SkeletonTable = () => (
    <div style={{ height: 450, width: "100%" }}>
      {[...Array(10)].map((_, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <Skeleton variant="rectangular" height={30} animation="wave" />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_V1}fetchAllPhraseCategoryByLanguageId`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: { languageId: receivedData },
          }
        );
        if (response.data) {
          setData(response.data.data);
          console.log(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Navbar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <SkeletonTable />
          </Box>
        </Box>
      </>
    );
  }

  const rowsWithIds = data.map(
    ({ _id, languageId, phraseName, icon, createdAt }) => ({
      id: _id,
      languageId,
      phraseName,
      icon,
      createdAt,
    })
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                position: "absolute",
                zIndex: 1500,
                width: "50%",
                margin: "auto",
              }}
            >
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="filled" severity="success">
              {success}
            </Alert>
          )}
          <h3>All Phrase Category</h3>
          <div>
            <TextField
              id="outlined-basic"
              label="Phrase Category Name"
              variant="outlined"
              value={searchPhraseCategoryValue}
              onChange={handleSearchPhraseCategoryValue}
              sx={{ width: 250, marginBottom: "10px" }}
            />
          </div>
          <Button
            variant="contained"
            sx={{ marginBottom: "10px" }}
            className="submitBtn"
            onClick={handleOpen}
          >
            Create New Phrase Category
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Phrase Category
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Box sx={{ width: 250, marginBottom: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={languageId}
                      label="Language"
                      onChange={handleLanguageValue}
                    >
                      {languages.map((language) => (
                        <MenuItem key={language._id} value={language._id}>
                          {language.languageName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <div style={{ marginBottom: "10px" }}>
                  <label for="myFile" class="file-label">
                    Select Icon
                    <input
                      type="file"
                      id="myFile"
                      name="filename"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>

                <div>
                  <TextField
                    id="outlined-basic"
                    label="Phrase Category Name"
                    variant="outlined"
                    value={categoryValue}
                    onChange={handleCategoryValue}
                    sx={{ width: 250 }}
                  />
                </div>
                <Button
                  variant="contained"
                  sx={{ marginBottom: "10px", marginTop: "10px" }}
                  className="submitBtn"
                  onClick={submitResultCategory}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Typography>
            </Box>
          </Modal>

          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Are you sure to delete this phrase category
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                  className="deleteModalBtn"
                  onClick={handleDeleteClick}
                >
                  {isBtnLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Yes"
                  )}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  className="noBtn"
                  onClick={handleDeleteClose}
                >
                  No
                </Button>
              </Typography>
            </Box>
          </Modal>

          <div style={{ height: 450, width: "100%" }}>
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    display: "none",
                  },
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                ".MuiDataGrid-selectedRowCount": {
                  display: "none",
                },
              }}
              pageSizeOptions={[2, 5, 10]}
              checkboxSelection={false}
            />
          </div>
        </Box>
      </Box>
    </>
  );
}
